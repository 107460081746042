.copy{
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    position: relative;
    top: 0px;
    vertical-align: bottom;
    z-index: 1;
    height: 40px;
    margin-right: -10px;
    padding: 10px;
    width: 40px;
    fill: white;
}

.btn .noborder { 
    border: none;
}
// .media{
//     display: flex;
// }
// .media-component{
//     display: inline-flex;
//     .image-gallery{
//         display: block;
//         padding: 1rem;
//         .media-pagination{
//             width: 100%;
//             justify-content: right;
//             float: right;
//             button{
//                 cursor: pointer;
//             }
            
//         }
//     }
//     .image-uploader{
//         width: 25rem;
    
//         .card{
//             height: 35rem;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             font-size: 2em;
//         }
//     }
// }
